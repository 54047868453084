import { FarmActivity } from '@/models/FarmActivity'
import { User } from '@/models/User'

export class FarmActivityConverter implements firebase.firestore.FirestoreDataConverter<FarmActivity> {
  toFirestore(activity: FarmActivity): firebase.firestore.DocumentData {
    return {
      text: activity.text,
      done: activity.done,
      user: activity.user.reference
    }
  }

  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
    options: firebase.firestore.SnapshotOptions) {
    const data = snapshot.data(options)
    const activity = new FarmActivity(snapshot.ref)
    activity.text = data.text
    activity.done = data.done
    activity.createdAt = data.created_at.toDate()
    if (data.user) {
      activity.user = new User(data.user)
    }

    return activity
  }
}