







































import { Component, Vue } from 'vue-property-decorator'
import Tile from '@/components/Tile.vue'
import Table from '@/components/Table.vue'
import Button from '@/components/Button.vue'
import FarmSwitcher from '@/components/FarmSwitcher.vue'
import { mixins } from 'vue-class-component'
import FarmMixin from '@/mixins/FarmMixin.vue'
import { Farm } from '@/models/Farm'
import { FarmPeriod } from '@/models/FarmPeriod'
import { FarmActivityConverter } from '@/models/converters/FarmActivityConverter'
import { firestore } from 'firebase/app'
import { FarmConverter } from '@/models/converters/FarmConverter'
import { showNotification } from '@/services/NotificationService'
import UserMixin from '@/mixins/UserMixin.vue'
import { TableHeader } from '@/models/TableHeader'
import { FarmItem } from '@/models/FarmItem'
import { FarmActivity } from '@/models/FarmActivity'

@Component({
  components: {
    Tile,
    Table,
    Button,
    FarmSwitcher
  }
})
export default class AdminFarmActivities extends mixins(UserMixin, FarmMixin) {
  farm: Farm = null
  headers: TableHeader[] = [
    { key: 'text', title: 'Text' },
    { key: 'createdAt', title: 'Datum', classes: ['hidden', 'lg:table-cell'] },
    { key: 'action', title: '' }
  ]
  query: firestore.Query<FarmActivity> = null

  async created() {
    if (this.currentFarm) {
      this.farm = this.currentFarm

      await this.loadItems()
    }
  }

  async loadItems() {
    this.query = firestore()
      .collection('farms')
      .doc(this.farm.id)
      .collection('activities')
      .withConverter(new FarmActivityConverter())
      .orderBy('created_at', 'desc')
      .orderBy('done', 'asc')
  }

  async markAsDone(activity: FarmActivity) {
    activity.done = true
    activity.user = this.user
    await activity.reference
      .withConverter(new FarmActivityConverter())
      .set(activity, { merge: true })
    showNotification({
      title: 'Erfolg',
      text: 'Als "Erledigt" markiert.',
      type: 'success',
      timeout: 10
    })
  }

  async farmChanged() {
    await this.loadItems()
  }
}
