import { Model } from '@/models/Model'
import { User } from '@/models/User'

class FarmActivity extends Model {
  text: string
  done: boolean
  createdAt: Date
  user: User

  constructor(reference?: firebase.firestore.DocumentReference) {
    super(reference)
    this.text = ''
    this.done = false
    this.createdAt = null
    this.user = null
  }
}

export { FarmActivity }